import { classnames } from "@vgno/utils";

import { EventStatus } from "./EventStatus/EventStatus";
import { TeaserImage } from "./TeaserImage";

import type { Article } from "@vgno/article";
import type { TeaserProps } from "@vgno/teasers/Teaser";
import type { ImageAsset } from "@vgno/vg-types";

import styles from "./VGLiveTeaser.module.css";

export const VGLiveTeaser = ({
  customProperties,
  imageAsset,
  loading,
  size = "medium",
  title,
  url,
}: VGLiveTeaserProps) => {
  if (!imageAsset || !url) {
    // It's decided that VGLiveTeaser must have an image to be displayed
    // VGLiveTeaser not linking anywhere doesn't make sense so don't render it if url is missing.
    return null;
  }

  return (
    <article
      className={classnames("sport-teaser", styles.article, styles[size])}
      // Force teaser to be in always in dark mode to have consistent gradient color on the image
      data-color-scheme="dark"
    >
      <a className={styles.link} href={url}>
        <EventStatus teaserCustomProperties={customProperties} />
        {customProperties.tournamentLogoURL && (
          <img
            className={styles.tournamentLogo}
            src={customProperties.tournamentLogoURL}
            alt=""
          />
        )}
        <TeaserImage
          alt=""
          imageAsset={imageAsset}
          loading={loading}
          size={size}
        />
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>{title}</h2>
        </div>
      </a>
    </article>
  );
};

type VGLiveTeaserProps = {
  customProperties: Article["customProperties"];
  imageAsset?: ImageAsset;
  loading?: TeaserProps["loading"];
  size: TeaserProps["size"];
  title: string;
  url?: string;
};
